<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-form @submit="handleSubmit" :form="form" :loading="formLoading">
      <a-form-item label="类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-tree-select style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="treeData" v-decorator="[
            'cate_id',
            {
              rules: [{ required: true, message: '需选择父级节点' }]
            }
          ]" placeholder="Please select" tree-default-expand-all>
        </a-tree-select>
      </a-form-item>
      <a-form-item label="标题" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'title',
          {
            rules: [{ required: true, message: '标题不能为空' }],
            initialValue: info.title
          },
        ]" placeholder="新闻标题" />
      </a-form-item>
      <a-form-item label="关键字" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'keywords',
          {
            rules: [{ required: true, message: '关键字不能为空' }],
            initialValue: info.keywords
          },
        ]" placeholder="新闻关键字" />
      </a-form-item>
      <a-form-item label="描述" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'description',
          {
            rules: [{ required: true, message: '描述不能为空' }],
            initialValue: info.description
          },
        ]" placeholder="新闻描述" />
      </a-form-item>
      <a-form-item label="访问量" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'pv',
          {
            initialValue: info.pv
          },
        ]" placeholder="访问量" />
      </a-form-item>
      <a-form-item label="内容" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <quill-editor v-decorator="[
          'content',
          {
            rules: [{ required: true, message: '描述不能为空' }],
            initialValue: info.content
          },
        ]" @change="quillEditorChange" />
      </a-form-item>
      <a-form-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-checkbox v-decorator="[
          'status',
          {
            valuePropName: 'checked',
            initialValue: infoStatus,
          },
        ]">
          是否展示
        </a-checkbox>
      </a-form-item>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button htmlType="submit" :loading="submitLoading" type="primary">提交</a-button>
        <a-button style="margin-left: 8px" @click="routerback">返回</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import QuillEditor from '@/components/Editor/QuillEditor'
import { TreeSelect as ATreeSelect } from 'ant-design-vue'

export default {
  name: 'NewsEdit',
  components: {
    QuillEditor,
    ATreeSelect

  },
  data() {
    return {
      description: '新闻编辑添加',
      value: 1,
      fontStyle: {
        fontWeight: 1000
      },
      treeData: [],
      labelCol: {
        lg: { span: 4 },
        md: { span: 4 },
        sm: { span: 24 }
      },
      wrapperCol: {
        lg: { span: 20 },
        md: { span: 20 },
        sm: { span: 24 }
      },
      queryData: {},
      cates: {},
      info: {
        classify: '公司新闻'
      },
      infoStatus: false,
      pageType: 'add', // 当前页面是新增(add),还是修改(edit)

      // form
      form: this.$form.createForm(this),
      formLoading: false,
      submitLoading: false,
      content: ''
    }
  },
  created() {
    const id = this.$route.query.id
    this.queryData.id = id
    if (id !== undefined) {
      this.pageType = 'edit'
      this.loadInfo(id)
    } else {
      this.loadType()
    }
  },
  methods: {
    loadInfo(id) {
      this.formLoading = true
      this.$http({
        url: '/admin/helps/info',
        params: { 'id': id }
      }).then(res => {
        if (res.status === 'success') {
          const data = res.data
          this.info = data.info
          if (data.info.status === 1) {
            this.infoStatus = true
          } else {
            this.infoStatus = false
          }

          const cates = data.cates
          const filterData = []
          cates.forEach((value, index) => {
            const obj = {}
            obj.id = value.id
            obj.pid = value.pid
            obj.key = value.id
            obj.title = value.name
            obj.value = value.id
            filterData[index] = obj
          })
          const handleData = this.getChild(filterData, 0)
          this.treeData = handleData
          this.form.setFieldsValue({
            cate_id: data.info.cate_id
          })
        } else {
          this.$notification.error({
            message: res.status,
            description: res.message
          })
          // 跳转回
          setTimeout(() => {
            this.$router.back(-1)
          }, 2000)
        }
      }).finally(() => {
        this.formLoading = false
      })
    },
    loadType() {
      this.$http({
        url: '/admin/helps/additional'
      }).then(res => {
        if (res.status === 'success') {
          const data = res.data.cates
          const filterData = []
          data.forEach((value, index) => {
            const obj = {}
            obj.id = value.id
            obj.pid = value.pid
            obj.key = value.id
            obj.title = value.name
            obj.value = value.id
            if (value.pid === 0) {
              obj.disabled = true
            }
            filterData[index] = obj
          })
          const handleData = this.getChild(filterData, 0)
          this.treeData = handleData
        } else {
          this.$notification.error({
            message: res.status,
            description: res.message
          })
          // 跳转回
          setTimeout(() => {
            this.$router.back(-1)
          }, 2000)
        }
      })
    },
    getChild(data, id) {
      const child = []
      var i = 0
      data.forEach((value, index) => {
        if (value.pid === id) {
          child[i] = value
          const children = this.getChild(data, value.id)
          if (children.length > 0) {
            child[i].children = children
          }
          i = i + 1
        }
      })
      return child
    },
    quillEditorChange(html) {
      this.content = html
    },
    // handler
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          values.status = values.status ? 1 : 0
          if (this.pageType === 'edit') {
            this.submitLoading = true
            values.id = this.queryData.id
            // 提交数据
            this.$http({
              url: '/admin/helps/save',
              method: 'post',
              data: values
            }).then(res => {
              if (res.status === 'success') {
                this.$notification.success({
                  message: res.status,
                  description: res.message
                })
                // 跳转回列表页面
                setTimeout(() => {
                  this.$router.back(-1)
                }, 2000)
              } else {
                this.$notification.error({
                  message: '错误',
                  description: res.message
                })
              }
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            // 新增
            this.submitLoading = true
            values.id = this.queryData.id
            // 提交数据
            this.$http({
              url: '/admin/helps/create',
              method: 'post',
              data: values
            }).then(res => {
              if (res.status === 'success') {
                this.$notification.success({
                  message: res.status,
                  description: res.message
                })
                // 跳转回列表页面
                setTimeout(() => {
                  this.$router.back(-1)
                }, 2000)
              } else {
                this.$notification.error({
                  message: '错误',
                  description: res.message
                })
              }
            }).finally(() => {
              this.submitLoading = false
            })
          }
        }
      })
    },
    routerback: function () {
      this.$router.back(-1)
    }
  }
}
</script>
